import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addSubscriptionPlanAsync,
  getAllSubscriptionPlanAsync,
  getSubscriptionPlanByIdAsync,
  updateSubscriptionPlanByIdAsync,
  updateSubscriptionPlanStatusAsync,
} from "./subscription.async";

const initialState = {
  subscriptionLoader: false,
  getAllSubscription: [],
  subscriptionadd: [],
  subscriptionById: [],
  subscriptionupdate: [],
  subscriptionStatus: [],
};

export const subscriptionsPlanSlice = createSlice({
  name: "Subscription",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllSubscriptionPlanAsync.pending,
        addSubscriptionPlanAsync.pending,
        updateSubscriptionPlanByIdAsync.pending,
        getSubscriptionPlanByIdAsync.pending,
        updateSubscriptionPlanStatusAsync.pending
      ),
      (state) => {
        state.subscriptionLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllSubscriptionPlanAsync.fulfilled),
      (state, action) => {
        state.subscriptionLoader = false;
        state.getAllSubscription = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addSubscriptionPlanAsync.fulfilled),
      (state, action) => {
        state.subscriptionLoader = false;
        state.subscriptionadd = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getSubscriptionPlanByIdAsync.fulfilled),
      (state, action) => {
        state.subscriptionLoader = false;
        state.subscriptionById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateSubscriptionPlanByIdAsync.fulfilled),
      (state, action) => {
        state.subscriptionLoader = false;
        state.subscriptionupdate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateSubscriptionPlanStatusAsync.fulfilled),
      (state, action) => {
        state.subscriptionLoader = false;
        state.subscriptionStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllSubscriptionPlanAsync.rejected,
        addSubscriptionPlanAsync.rejected,
        updateSubscriptionPlanByIdAsync.rejected,
        getSubscriptionPlanByIdAsync.rejected,
        updateSubscriptionPlanStatusAsync.rejected
      ),
      (state, action) => {
        state.subscriptionLoader = false;
      }
    );
  },
  reducers: {
    emptySubscription: () => initialState,
  },
});

export const { emptySubscription } = subscriptionsPlanSlice.actions;

export default subscriptionsPlanSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createStreamAsync = createAsyncThunk(
  "admin/createStream",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createStream`, payload, toolkit);
  }
);

export const getStreamAsync = createAsyncThunk(
  "admin/getStream",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getStream/${payload}`, payload, toolkit);
  }
);

export const getAllStreamAsync = createAsyncThunk(
  "admin/getAllStream",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllStream?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

export const updateStreamByIdAsync = createAsyncThunk(
  "admin/updateStreamById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateStreamById`, payload, toolkit);
  }
);

export const deleteStreamAsync = createAsyncThunk(
  "admin/deleteStream",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteStream/${payload}`,
      payload,
      toolkit
    );
  }
);

export const updateStreamStatusAsync = createAsyncThunk(
  "admin/updateStreamStatus",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateStreamStatus`, payload, toolkit);
  }
);

import { AxiosClient } from "redux/AxiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addGoalLanguageAsync = createAsyncThunk(
  "admin/addGoalLanguage",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addGoalLanguage`, payload, toolkit);
  }
);

export const getGoalLanguageByIdAsync = createAsyncThunk(
  "admin/getGoalLanguageById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getGoalLanguageById/${payload}`,
      [],
      toolkit
    );
  }
);

export const getAllGoalLanguageAsync = createAsyncThunk(
  "admin/getAllGoalLanguage",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllGoalLanguage?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }`,
      [],
      toolkit
    );
  }
);

export const updateGoalLanguageByIdAsync = createAsyncThunk(
  "admin/updateGoalLanguageById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateGoalLanguageById`,
      payload,
      toolkit
    );
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AxiosClient } from "redux/AxiosClient";

export const UploadBulkQuestionsAsync = createAsyncThunk(
  "admin/uploadBulkQuestion",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/uploadBulkQuestion`, payload, toolkit);
  }
);

export const uploadQuestionOfDayExcelAsync = createAsyncThunk(
  "admin/uploadQuestionOfDayExcel",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadQuestionOfDayExcel`,
      payload,
      toolkit
    );
  }
);

export const bulkquestionOutAsync = createAsyncThunk(
  "admin/bulkquestionOutAsync",
  async (payload, toolkit) => {
    return await axios
      .post(`${process.env.REACT_APP_QB_UPLOAD}/convert`, payload)
      .then((response) => {
        return toolkit.fulfillWithValue(response.data);
      })
      .catch((error) => {
        // toolkit.dispatch(errorMessage(error.response.data.message));
        return toolkit.rejectWithValue(error.response.data.message);
      });
  }
);
export const bulkquestionInAsync = createAsyncThunk(
  "admin/bulkquestionInAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/uploadBulkQuestions`, payload, toolkit);
  }
);
export const bulkuploadquestionAsync = createAsyncThunk(
  "admin/uploadBulkQuestion",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadBulkQuestion`,
      payload,
      toolkit,
      "multipart/form-data"
    );
  }
);
export const getAllQuestionAsync = createAsyncThunk(
  "admin/getAllQuestion",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getQuestionByGoalSubject`,
      payload,
      toolkit
    );
  }
);

export const getAllEnglishQuestionAsync = createAsyncThunk(
  "admin/getAllQOfTheDay",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllQOfTheDay?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&classes=${payload.classes || ""}`,
      [],
      toolkit
    );
  }
);

export const getAllQuestionBankAsync = createAsyncThunk(
  "admin/getAllQuestion",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllQuestion?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&goal=${payload.goal || ""}&subject=${payload.subject || ""}&chapter=${
        payload.chapter || ""
      }`,
      [],
      toolkit
    );
  }
);

export const updateQuestionsStatusAsync = createAsyncThunk(
  "admin/createQuestionBank",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PATCH",
      `/updateQuestionsStatus`,
      payload,
      toolkit
    );
  }
);

export const addQuestiosAsync = createAsyncThunk(
  "admin/addQuestios",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addQuestios`, payload, toolkit);
  }
);

export const updateQuestionIdAsync = createAsyncThunk(
  "admin/updateQOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateQOfTheDayById`, payload, toolkit);
  }
);

export const getEngQuestionByIdAsync = createAsyncThunk(
  "admin/getQOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getQOfTheDayById/${payload}`,
      [],
      toolkit
    );
  }
);

export const createQuestionBankAsync = createAsyncThunk(
  "admin/createQuestionBank",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/createQuestionBank`, payload, toolkit);
  }
);
export const getQuestionByIdAsync = createAsyncThunk(
  "admin/getQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getQuestionById/${payload}`, [], toolkit);
  }
);
export const updateQuestionAsync = createAsyncThunk(
  "admin/updateQuestionBankId",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateQuestionBankId`, payload, toolkit);
  }
);

export const deleteQOfTheDayByIdAsync = createAsyncThunk(
  "admin/deleteQOfTheDayById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteQOfTheDayById/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteQuestionAsync = createAsyncThunk(
  "admin/deleteQuestionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteQuestionById/${payload}`,
      [],
      toolkit
    );
  }
);
export const getChapterByMultipleSubjectIdAsync = createAsyncThunk(
  "admin/getChapterByMultipleSubjectId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getChapterByMultipleSubjectId`,
      payload,
      toolkit
    );
  }
);
export const getAllBulkFilesAsync = createAsyncThunk(
  "admin/getAllBulkFiles",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllBulkFiles?page=${payload.page}&limit=${payload.limit}&fileType=${payload.fileType}`,
      [],
      toolkit
    );
  }
);

// get question for scholarship test
export const getQuestionScholarshipTestAsync = createAsyncThunk(
  "admin/getQuestionScholarshipTestAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getScholarshipQuestions`,
      payload,
      toolkit
    );
  }
);

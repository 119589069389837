import { AxiosClient } from "redux/AxiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addMasterStudyMatrialsAsync = createAsyncThunk(
  "admin/addMasterStudyMatrials",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/addMasterStudyMatrials`,
      payload,
      toolkit
    );
  }
);

export const getAllMasterStudyMatrialsAsync = createAsyncThunk(
  "admin/getAllMasterStudyMatrials",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllMasterStudyMatrials?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}&status=${payload.status || ""}`,
      [],
      toolkit
    );
  }
);

export const getMasterStudyMatrialsByIdAsync = createAsyncThunk(
  "admin/getMasterStudyMatrials",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getMasterStudyMatrials/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateMasterStudyMatrialsByIdAsync = createAsyncThunk(
  "admin/updateMasterStudyMatrialsById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateMasterStudyMatrialsById`,
      payload,
      toolkit
    );
  }
);

export const updateMasterStudyMatrialsStatusAsync = createAsyncThunk(
  "admin/updateMasterStudyMatrialsStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateMasterStudyMatrialsStatus`,
      payload,
      toolkit
    );
  }
);

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllTestAsync,
  createTestAsync,
  deleteTestByIdAsync,
  getTestByIdAsync,
  getStudentTestReportGraphAsync,
  updateTestByIdAsync,
  addMasterTestAsync,
  getAllMasterTestAsync,
  getMasterTestAsync,
  updateMasterTestByIdAsync,
  masterTestByGoalIdAsync,
} from "../TestSlice/async.api";

const initialState = {
  testLoader: false,
  tests: [],
  createTest: [],
  updateTest: [],
  testdelete: [],
  getTestById: [],
  getStudentTestReportGraph: [],
  addMasterTest: [],
  masterTests: [],
  masterTestById: [],
  updateMasterTest: [],
  masterTestType: [],
};

export const testSlice = createSlice({
  name: "tests",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllTestAsync.pending,
        createTestAsync.pending,
        deleteTestByIdAsync.pending,
        getTestByIdAsync.pending,
        getStudentTestReportGraphAsync.pending,
        updateTestByIdAsync.pending,
        addMasterTestAsync.pending,
        getAllMasterTestAsync.pending,
        getMasterTestAsync.pending,
        updateMasterTestByIdAsync.pending,
        masterTestByGoalIdAsync.pending
      ),
      (state) => {
        state.testLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(masterTestByGoalIdAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.masterTestType = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(addMasterTestAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.addMasterTest = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllMasterTestAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.masterTests = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getMasterTestAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.masterTestById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateMasterTestByIdAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.updateMasterTest = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(getAllTestAsync.fulfilled), (state, action) => {
      state.testLoader = false;
      state.tests = action.payload;
    });

    builder.addMatcher(isAnyOf(createTestAsync.fulfilled), (state, action) => {
      state.testLoader = false;
      state.createTest = action.payload;
    });

    builder.addMatcher(
      isAnyOf(updateTestByIdAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.updateTest = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteTestByIdAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.testdelete = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getTestByIdAsync.fulfilled), (state, action) => {
      state.testLoader = false;
      state.getTestById = action.payload;
    });
    builder.addMatcher(
      isAnyOf(getStudentTestReportGraphAsync.fulfilled),
      (state, action) => {
        state.testLoader = false;
        state.getStudentTestReportGraph = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllTestAsync.rejected,
        createTestAsync.rejected,
        deleteTestByIdAsync.rejected,
        getTestByIdAsync.rejected,
        getStudentTestReportGraphAsync.rejected,
        updateTestByIdAsync.rejected,
        addMasterTestAsync.rejected,
        getAllMasterTestAsync.rejected,
        getMasterTestAsync.rejected,
        updateMasterTestByIdAsync.rejected,
        masterTestByGoalIdAsync.rejected
      ),
      (state, action) => {
        state.testLoader = false;
      }
    );
  },
  reducers: {
    emptytests: (state) => {
      return {
        ...initialState,
      };
    },
  },
});
export const { emptytests } = testSlice.actions;
export default testSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../../../AxiosClient";

export const createAgentVoucherAsync = createAsyncThunk(
  "admin/agentVoucher",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/agentVoucher`, payload, toolkit);
  }
);

export const getAgentAllVoucherAsync = createAsyncThunk(
  "admin/getAgentAllVoucher",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAgentAllVoucher?page=${payload.page}&limit=${payload.limit}&search=${
        payload.search || ""
      }`,
      [],
      toolkit
    );
  }
);

export const getAgentVoucherByIdAsync = createAsyncThunk(
  "admin/getAgentVoucherById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAgentVoucherById/${payload}`,
      payload,
      toolkit
    );
  }
);

export const updateAgentVoucherByIdAsync = createAsyncThunk(
  "admin/updateAgentVoucherById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateAgentVoucherById`,
      payload,
      toolkit
    );
  }
);

export const updateVoucherStatusAsync = createAsyncThunk(
  "admin/updateVoucherStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateVoucherStatus/${payload}`,
      [],
      toolkit
    );
  }
);

export const agentVoucherUsersAsync = createAsyncThunk(
  "admin/agentVoucherUsers",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/agentVoucherUsers?page=${payload.page}&limit=${payload.limit}&Id=${
        payload.id
      }&search=${payload.search || ""}`,
      payload,
      toolkit
    );
  }
);

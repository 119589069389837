import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  userReferEarnAsync,
  agentsReferCodeAsync,
  generateReferCodeAsync,
} from "./refer.async";

const initialState = {
  refandearnLoader: false,
  users: [],
  agents: [],
  generatedCode: [],
};

export const referSlice = createSlice({
  name: "refer",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        userReferEarnAsync.pending,
        agentsReferCodeAsync.pending,
        generateReferCodeAsync.pending
      ),
      (state) => {
        state.refandearnLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(userReferEarnAsync.fulfilled),
      (state, action) => {
        state.refandearnLoader = false;
        state.users = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(generateReferCodeAsync.fulfilled),
      (state, action) => {
        state.refandearnLoader = false;
        state.generatedCode = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(agentsReferCodeAsync.fulfilled),
      (state, action) => {
        state.refandearnLoader = false;
        state.agents = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        userReferEarnAsync.rejected,
        agentsReferCodeAsync.rejected,
        generateReferCodeAsync.rejected
      ),
      (state, action) => {
        state.refandearnLoader = false;
      }
    );
  },
  reducers: {
    emptyrefer: () => initialState,
  },
});

export const { emptyrefer } = referSlice.actions;

export default referSlice.reducer;

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createAgentVoucherAsync,
  getAgentAllVoucherAsync,
  getAgentVoucherByIdAsync,
  updateAgentVoucherByIdAsync,
  updateVoucherStatusAsync,
  agentVoucherUsersAsync,
} from "./agentvoucher.async";

const initialState = {
  agentVoucherLoader: false,
  addAgentVoucher: [],
  updateAgentVoucher: [],
  agentVoucherById: [],
  agentVouchers: [],
  agentVoucherStatus: [],
  usersList: [],
};

export const agentVoucherSlice = createSlice({
  name: "tests",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        createAgentVoucherAsync.pending,
        getAgentAllVoucherAsync.pending,
        getAgentVoucherByIdAsync.pending,
        updateAgentVoucherByIdAsync.pending,
        updateVoucherStatusAsync.pending,
        agentVoucherUsersAsync.pending
      ),
      (state) => {
        state.agentVoucherLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(agentVoucherUsersAsync.fulfilled),
      (state, action) => {
        state.agentVoucherLoader = false;
        state.usersList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(createAgentVoucherAsync.fulfilled),
      (state, action) => {
        state.agentVoucherLoader = false;
        state.addAgentVoucher = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAgentAllVoucherAsync.fulfilled),
      (state, action) => {
        state.agentVoucherLoader = false;
        state.agentVouchers = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAgentVoucherByIdAsync.fulfilled),
      (state, action) => {
        state.agentVoucherLoader = false;
        state.agentVoucherById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateAgentVoucherByIdAsync.fulfilled),
      (state, action) => {
        state.agentVoucherLoader = false;
        state.updateAgentVoucher = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateVoucherStatusAsync.fulfilled),
      (state, action) => {
        state.agentVoucherLoader = false;
        state.agentVoucherStatus = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        createAgentVoucherAsync.rejected,
        getAgentAllVoucherAsync.rejected,
        getAgentVoucherByIdAsync.rejected,
        updateAgentVoucherByIdAsync.rejected,
        updateVoucherStatusAsync.rejected,
        agentVoucherUsersAsync.rejected
      ),
      (state, action) => {
        state.agentVoucherLoader = false;
      }
    );
  },
  reducers: {
    emptyagentvoucher: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyagentvoucher } = agentVoucherSlice.actions;
export default agentVoucherSlice.reducer;

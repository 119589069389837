import { AxiosClient } from "redux/AxiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addStudyMatrialsMapAsync = createAsyncThunk(
  "admin/addStudyMatrialsMap",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addStudyMatrialsMap`, payload, toolkit);
  }
);

export const getAllStudyMatrialsMapAsync = createAsyncThunk(
  "admin/getAllStudyMatrialsMap",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllStudyMatrialsMap?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}&status=${payload.status || ""}`,
      [],
      toolkit
    );
  }
);

export const getStudyMatrialsMapByIdAsync = createAsyncThunk(
  "admin/getStudyMatrialsMap",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getStudyMatrialsMap/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateStudyMatrialsMapByIdAsync = createAsyncThunk(
  "admin/updateStudyMatrialsMapById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateStudyMatrialsMapById`,
      payload,
      toolkit
    );
  }
);

export const updateMasterStudyMatrialsStatusAsync = createAsyncThunk(
  "admin/updateMasterStudyMatrialsStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateMasterStudyMatrialsStatus`,
      payload,
      toolkit
    );
  }
);

export const studyMatrialsByGoalIdAsync = createAsyncThunk(
  "admin/studyMatrialsByGoalId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/studyMatrialsByGoalId?goalId=${payload.goalId}`,
      [],
      toolkit
    );
  }
);

export const deleteStudyMaterialAsync = createAsyncThunk(
  "admin/deleteStudyMaterial",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteStudyMaterial/${payload}`,
      [],
      toolkit
    );
  }
);

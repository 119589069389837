import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  uploadStudyMaterialExcelAsync,
  getStudyBulkFiles,
} from "./StudyBulk.async.api";

const initialState = {
  studyBulkLoader: false,
  studyBulkUpload: [],
  studyBulkFiles: [],
};

export const studyBulkSlice = createSlice({
  name: "studyBulk",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(uploadStudyMaterialExcelAsync.pending, getStudyBulkFiles.pending),
      (state) => {
        state.studyBulkLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(uploadStudyMaterialExcelAsync.fulfilled),
      (state, action) => {
        state.studyBulkLoader = false;
        state.studyBulkUpload = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getStudyBulkFiles.fulfilled),
      (state, action) => {
        state.studyBulkLoader = false;
        state.studyBulkFiles = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        uploadStudyMaterialExcelAsync.rejected,
        getStudyBulkFiles.rejected
      ),
      (state, action) => {
        state.studyBulkLoader = false;
      }
    );
  },

  reducers: {
    emptystudyBulk: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptystudyBulk } = studyBulkSlice.actions;

export default studyBulkSlice.reducer;

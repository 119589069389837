import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createStreamAsync,
  getStreamAsync,
  getAllStreamAsync,
  updateStreamByIdAsync,
  deleteStreamAsync,
  updateStreamStatusAsync,
} from "./stream.async";

const initialState = {
  streamLoader: false,
  streams: [],
  streamadd: [],
  streamById: [],
  streamupdate: [],
  deletedStream: [],
  updateStatus: [],
};

export const streamSlice = createSlice({
  name: "stream",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllStreamAsync.pending,
        createStreamAsync.pending,
        getStreamAsync.pending,
        updateStreamByIdAsync.pending,
        deleteStreamAsync.pending,
        updateStreamStatusAsync.pending
      ),
      (state) => {
        state.streamLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllStreamAsync.fulfilled),
      (state, action) => {
        state.streamLoader = false;
        state.streams = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createStreamAsync.fulfilled),
      (state, action) => {
        state.streamLoader = false;
        state.streamadd = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getStreamAsync.fulfilled), (state, action) => {
      state.streamLoader = false;
      state.streamById = action.payload.data;
    });
    builder.addMatcher(
      isAnyOf(updateStreamByIdAsync.fulfilled),
      (state, action) => {
        state.streamLoader = false;
        state.streamupdate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteStreamAsync.fulfilled),
      (state, action) => {
        state.streamLoader = false;
        state.deletedStream = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateStreamStatusAsync.fulfilled),
      (state, action) => {
        state.streamLoader = false;
        state.updateStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllStreamAsync.rejected,
        createStreamAsync.rejected,
        getStreamAsync.rejected,
        updateStreamByIdAsync.rejected,
        deleteStreamAsync.rejected,
        updateStreamStatusAsync.rejected
      ),
      (state, action) => {
        state.streamLoader = false;
      }
    );
  },
  reducers: {
    emptystream: () => initialState,
  },
});

export const { emptystream } = streamSlice.actions;

export default streamSlice.reducer;

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addGoalLanguageAsync,
  getGoalLanguageByIdAsync,
  updateGoalLanguageByIdAsync,
  getAllGoalLanguageAsync,
} from "./goalLanguage.async";

const initialState = {
  goalLanguageLoader: false,
  addGoalLanguage: [],
  getAllGoalLanguage: [],
  goalLanguageById: [],
  updateGoalLanguage: [],
};

export const goalLanguageSlice = createSlice({
  name: "stream",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addGoalLanguageAsync.pending,
        getAllGoalLanguageAsync.pending,
        getGoalLanguageByIdAsync.pending,
        updateGoalLanguageByIdAsync.pending
      ),
      (state) => {
        state.goalLanguageLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllGoalLanguageAsync.fulfilled),
      (state, action) => {
        state.goalLanguageLoader = false;
        state.getAllGoalLanguage = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addGoalLanguageAsync.fulfilled),
      (state, action) => {
        state.goalLanguageLoader = false;
        state.addGoalLanguage = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getGoalLanguageByIdAsync.fulfilled),
      (state, action) => {
        state.goalLanguageLoader = false;
        state.goalLanguageById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateGoalLanguageByIdAsync.fulfilled),
      (state, action) => {
        state.goalLanguageLoader = false;
        state.updateGoalLanguage = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllGoalLanguageAsync.rejected,
        addGoalLanguageAsync.rejected,
        getGoalLanguageByIdAsync.rejected,
        updateGoalLanguageByIdAsync.rejected
      ),
      (state, action) => {
        state.goalLanguageLoader = false;
      }
    );
  },
  reducers: {
    emptygoalLang: () => initialState,
  },
});

export const { emptygoalLang } = goalLanguageSlice.actions;

export default goalLanguageSlice.reducer;

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createCoursePackageAsync,
  updateCoursePackageByIdAsync,
  allCoursePackagesAsync,
  coursePackageByIdAsync,
  updateCoursePackageStatusAsync,
  getCoursePackagesAsync,
} from "./productPackage.async";

const initialState = {
  packageLoader: false,
  packagePostData: [],
  getAllPackage: [],
  updatePackage: [],
  getPackByIdData: [],
  packageStatus: [],
  allPackages: [],
};

export const productPackageSlice = createSlice({
  name: "Product",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getCoursePackagesAsync.pending,
        createCoursePackageAsync.pending,
        allCoursePackagesAsync.pending,
        coursePackageByIdAsync.pending,
        updateCoursePackageStatusAsync.pending,
        updateCoursePackageByIdAsync.pending
      ),
      (state) => {
        state.packageLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getCoursePackagesAsync.fulfilled),
      (state, action) => {
        state.packageLoader = false;
        state.allPackages = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(createCoursePackageAsync.fulfilled),
      (state, action) => {
        state.packageLoader = false;
        state.packagePostData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(allCoursePackagesAsync.fulfilled),
      (state, action) => {
        state.packageLoader = false;
        state.getAllPackage = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(coursePackageByIdAsync.fulfilled),
      (state, action) => {
        state.packageLoader = false;
        state.getPackByIdData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateCoursePackageStatusAsync.fulfilled),
      (state, action) => {
        state.packageLoader = false;
        state.packageStatus = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateCoursePackageByIdAsync.fulfilled),
      (state, action) => {
        state.packageLoader = false;
        state.updatePackage = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getCoursePackagesAsync.rejected,
        updateCoursePackageByIdAsync.rejected,
        updateCoursePackageStatusAsync.rejected,
        coursePackageByIdAsync.rejected,
        createCoursePackageAsync.rejected,
        allCoursePackagesAsync.rejected
      ),
      (state, action) => {
        state.packageLoader = false;
      }
    );
  },
  reducers: {
    emptySubsPackage: () => initialState,
  },
});

export const { emptySubsPackage } = productPackageSlice.actions;

export default productPackageSlice.reducer;

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addMasterGoalAsync,
  getMasterGoalByIdAsync,
  updateMasterGoalByIdAsync,
  getAllMasterGoalAsync,
  updateMasterGoalStatusAsync,
  coursePackagesByGoalIdAsync,
} from "./goalMapping.async";

const initialState = {
  goalMappingLoader: false,
  addGoalMapping: [],
  getAllGoalMapping: [],
  goalMappingById: [],
  updateGoalMapping: [],
  goalMappingStatus: [],
  getPackageByGoalIdLoader: false,
  getPackageByGoalId: [],
};

export const goalMappingSlice = createSlice({
  name: "goalmapping",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addMasterGoalAsync.pending,
        getAllMasterGoalAsync.pending,
        getMasterGoalByIdAsync.pending,
        updateMasterGoalByIdAsync.pending,
        updateMasterGoalStatusAsync.pending
      ),
      (state) => {
        state.goalMappingLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(coursePackagesByGoalIdAsync.pending),
      (state) => {
        state.getPackageByGoalIdLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(coursePackagesByGoalIdAsync.fulfilled),
      (state, action) => {
        state.getPackageByGoalIdLoader = false;
        state.getPackageByGoalId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllMasterGoalAsync.fulfilled),
      (state, action) => {
        state.goalMappingLoader = false;
        state.getAllGoalMapping = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addMasterGoalAsync.fulfilled),
      (state, action) => {
        state.goalMappingLoader = false;
        state.addGoalMapping = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getMasterGoalByIdAsync.fulfilled),
      (state, action) => {
        state.goalMappingLoader = false;
        state.goalMappingById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateMasterGoalByIdAsync.fulfilled),
      (state, action) => {
        state.goalMappingLoader = false;
        state.updateGoalMapping = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateMasterGoalStatusAsync.fulfilled),
      (state, action) => {
        state.goalMappingLoader = false;
        state.goalMappingStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllMasterGoalAsync.rejected,
        addMasterGoalAsync.rejected,
        getMasterGoalByIdAsync.rejected,
        updateMasterGoalByIdAsync.rejected,
        updateMasterGoalStatusAsync.rejected
      ),
      (state, action) => {
        state.goalMappingLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(coursePackagesByGoalIdAsync.rejected),
      (state, action) => {
        state.getPackageByGoalIdLoader = false;
      }
    );
  },
  reducers: {
    emptygoalMapping: () => initialState,
  },
});

export const { emptygoalMapping } = goalMappingSlice.actions;

export default goalMappingSlice.reducer;

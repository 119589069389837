import { AxiosClient } from "redux/AxiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addSubscriptionPlanAsync = createAsyncThunk(
  "admin/addSubscriptionPlan",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/addSubscriptionPlan`,
      payload,
      toolkit
    );
  }
);

export const getAllSubscriptionPlanAsync = createAsyncThunk(
  "admin/getAllSubscriptionPlan",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllSubscriptionPlan?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}&status=${payload.status || ""}`,
      [],
      toolkit
    );
  }
);

export const getSubscriptionPlanByIdAsync = createAsyncThunk(
  "admin/getSubscriptionPlanById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getSubscriptionPlanById/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateSubscriptionPlanByIdAsync = createAsyncThunk(
  "admin/updateSubscriptionPlanById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateSubscriptionPlanById`,
      payload,
      toolkit
    );
  }
);

export const updateSubscriptionPlanStatusAsync = createAsyncThunk(
  "admin/updateSubscriptionPlanStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateSubscriptionPlanStatus`,
      payload,
      toolkit
    );
  }
);

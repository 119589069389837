import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addMasterStudyMatrialsAsync,
  getAllMasterStudyMatrialsAsync,
  getMasterStudyMatrialsByIdAsync,
  updateMasterStudyMatrialsByIdAsync,
  updateMasterStudyMatrialsStatusAsync,
} from "./masterstudymaterial.async";

const initialState = {
  masterStudyMatrialLoader: false,
  getAllMasterStudyMaterial: [],
  masterstudymaterialadd: [],
  masterstudymaterialById: [],
  masterstudymaterialupdate: [],
  masterStudyMatrialStatus: [],
};

export const masterStudyMaterialSlice = createSlice({
  name: "Study Material",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllMasterStudyMatrialsAsync.pending,
        addMasterStudyMatrialsAsync.pending,
        updateMasterStudyMatrialsByIdAsync.pending,
        getMasterStudyMatrialsByIdAsync.pending,
        updateMasterStudyMatrialsStatusAsync.pending
      ),
      (state) => {
        state.masterStudyMatrialLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllMasterStudyMatrialsAsync.fulfilled),
      (state, action) => {
        state.masterStudyMatrialLoader = false;
        state.getAllMasterStudyMaterial = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addMasterStudyMatrialsAsync.fulfilled),
      (state, action) => {
        state.masterStudyMatrialLoader = false;
        state.masterstudymaterialadd = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getMasterStudyMatrialsByIdAsync.fulfilled),
      (state, action) => {
        state.masterStudyMatrialLoader = false;
        state.masterstudymaterialById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateMasterStudyMatrialsByIdAsync.fulfilled),
      (state, action) => {
        state.masterStudyMatrialLoader = false;
        state.masterstudymaterialupdate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateMasterStudyMatrialsStatusAsync.fulfilled),
      (state, action) => {
        state.masterStudyMatrialLoader = false;
        state.masterStudyMatrialStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllMasterStudyMatrialsAsync.rejected,
        addMasterStudyMatrialsAsync.rejected,
        updateMasterStudyMatrialsByIdAsync.rejected,
        getMasterStudyMatrialsByIdAsync.rejected,
        updateMasterStudyMatrialsStatusAsync.rejected
      ),
      (state, action) => {
        state.masterStudyMatrialLoader = false;
      }
    );
  },
  reducers: {
    emptymasterStudyMaterial: () => initialState,
  },
});

export const { emptymasterStudyMaterial } = masterStudyMaterialSlice.actions;

export default masterStudyMaterialSlice.reducer;

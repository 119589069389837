import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addStudyMatrialsMapAsync,
  getAllStudyMatrialsMapAsync,
  getStudyMatrialsMapByIdAsync,
  updateStudyMatrialsMapByIdAsync,
  updateMasterStudyMatrialsStatusAsync,
  studyMatrialsByGoalIdAsync,
  deleteStudyMaterialAsync,
} from "./studymaterialmapping.async";

const initialState = {
  studyMatrialMapLoader: false,
  getAllStudyMaterialMap: [],
  studymaterialmapadd: [],
  studymaterialmapById: [],
  studymaterialmapupdate: [],
  masterStudyMatrialStatus: [],
  studyMaterialByGoalId: [],
  deleteMaterial: [],
};

export const studymaterialmappingSlice = createSlice({
  name: "Study Material",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllStudyMatrialsMapAsync.pending,
        addStudyMatrialsMapAsync.pending,
        updateStudyMatrialsMapByIdAsync.pending,
        getStudyMatrialsMapByIdAsync.pending,
        updateMasterStudyMatrialsStatusAsync.pending,
        studyMatrialsByGoalIdAsync.pending,
        deleteStudyMaterialAsync.pending
      ),
      (state) => {
        state.studyMatrialMapLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteStudyMaterialAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.deleteMaterial = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllStudyMatrialsMapAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.getAllStudyMaterialMap = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(studyMatrialsByGoalIdAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.studyMaterialByGoalId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addStudyMatrialsMapAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.studymaterialmapadd = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getStudyMatrialsMapByIdAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.studymaterialmapById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateStudyMatrialsMapByIdAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.studymaterialmapupdate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateMasterStudyMatrialsStatusAsync.fulfilled),
      (state, action) => {
        state.studyMatrialMapLoader = false;
        state.masterStudyMatrialStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllStudyMatrialsMapAsync.rejected,
        addStudyMatrialsMapAsync.rejected,
        updateStudyMatrialsMapByIdAsync.rejected,
        getStudyMatrialsMapByIdAsync.rejected,
        updateMasterStudyMatrialsStatusAsync.rejected,
        studyMatrialsByGoalIdAsync.rejected,
        deleteStudyMaterialAsync.rejected
      ),
      (state, action) => {
        state.studyMatrialMapLoader = false;
      }
    );
  },
  reducers: {
    emptyStudyMaterialMapping: () => initialState,
  },
});

export const { emptyStudyMaterialMapping } = studymaterialmappingSlice.actions;

export default studymaterialmappingSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const userReferEarnAsync = createAsyncThunk(
  "admin/userReferEarn",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/userReferEarn?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

export const generateReferCodeAsync = createAsyncThunk(
  "admin/generateReferCode",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/generateReferCode`, payload, toolkit);
  }
);

export const agentsReferCodeAsync = createAsyncThunk(
  "admin/agentsReferCode",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/agentsReferCode?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

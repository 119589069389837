import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../../../AxiosClient";

export const uploadStudyMaterialExcelAsync = createAsyncThunk(
  "admin/uploadStudyMaterialExcel",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/uploadStudyMaterialExcel`,
      payload,
      toolkit
    );
  }
);

export const getStudyBulkFiles = createAsyncThunk(
  "admin/getAllBulkFiles",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllBulkFiles?page=${payload.page}&limit=${payload.limit}&fileType=studyMaterial`,
      [],
      toolkit
    );
  }
);

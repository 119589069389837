import { AxiosClient } from "redux/AxiosClient";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addMasterGoalAsync = createAsyncThunk(
  "admin/addMasterGoal",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addMasterGoal`, payload, toolkit);
  }
);

export const getMasterGoalByIdAsync = createAsyncThunk(
  "admin/getMasterGoalById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getMasterGoalById/${payload}`,
      [],
      toolkit
    );
  }
);

export const getAllMasterGoalAsync = createAsyncThunk(
  "admin/getAllMasterGoal",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllMasterGoal?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }`,
      [],
      toolkit
    );
  }
);

export const updateMasterGoalByIdAsync = createAsyncThunk(
  "admin/updateMasterGoalById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateMasterGoalById`, payload, toolkit);
  }
);

export const updateMasterGoalStatusAsync = createAsyncThunk(
  "admin/updateMasterGoalStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/updateMasterGoalStatus`,
      payload,
      toolkit
    );
  }
);

export const coursePackagesByGoalIdAsync = createAsyncThunk(
  "admin/coursePackagesByGoalId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/coursePackagesByGoalId?goalId=${payload?.goalId}`,
      [],
      toolkit
    );
  }
);
